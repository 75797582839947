window.Vue = require('vue')

Vue.component('Subscribe', require('./subscribe/Subscribe.vue').default)

const $vueContainers = document.querySelectorAll('.laravel-subscribe')
if ($vueContainers) {
  for (let i = 0; i < $vueContainers.length; i++) {
    // eslint-disable-next-line no-new
    new Vue({ el: $vueContainers[i] })
  }
}
