import Errors from "./errors"

export default class Form {
  constructor () {
    this.fields = {}
    this.errors = new Errors()
  }

  reset () {
    for (const field in this.fields) {
      this.fields[field] = ''
    }
    this.errors.clear()
  }

  data () {
    return Object.assign({}, this.fields)
  }
}
