<script>
import * as tracking from '../tracking'
import http from '../http'
import Form from '../form'

export default {
  props: {
    locale: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      form: new Form(),
      message: '',
      error: '',
      data: {}
    }
  },
  methods: {
    onSubmit () {
      this.message = ''
      this.error = ''
      this.data = Object.assign(this.form.data(), { 'locale': this.locale })

      http
        .post(this.url, this.data)
        .then(response => this.onSuccess(response))
        .catch(error => this.onFail(error))
    },
    onSuccess (response) {
      this.message = response.data.message
      this.form.reset()

      tracking.send(this.$refs.subscribeForm)
    },
    onFail (error) {
      if (error.response.status === 422) {
        this.form.errors.set(error.response.data.errors)
        return
      }
      if (error.response.status === 404) {
        this.error = 'Unable to reach API'
        return
      }
      if (error.response.status === 500) {
        this.error = error.response.data.message
      }
    }
  }
}
</script>
